.home {
  .home-profile {
    text-align: center;

    .home-avatar {
      padding: 0.5rem;

      img {
        display: inline-block;
        width: 8rem;
        height: auto;
        margin: 0 auto;
        @include border-radius(100%);
        @include box-shadow(0 0 0 0.3618em rgba(0, 0, 0, 0.05));
        @include transition(all 0.4s ease);

        [data-theme='dark'] & {
          @include box-shadow(0 0 0 0.3618em rgba(255, 255, 255, 0.05));
        }

        &:hover {
          position: relative;
          @include transform(translateY(-0.75rem));
        }
      }
    }

    .home-title {
      font-size: 1.25rem;
      font-weight: bold;
      margin: 0;

      &:not(:empty) {
        padding: 0.5rem;
      }
    }

    .home-subtitle {
      font-size: 1rem;
      font-weight: normal;
      margin: 0;
      padding: 0.5rem;
    }

    .links {
      padding: 0.5rem;
      font-size: 1.5rem;

      a * {
        vertical-align: text-bottom;
      }

      img {
        height: 1.5rem;
        padding: 0 0.25rem;
      }
    }

    .home-disclaimer {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: normal;
      margin: 0;
      padding: 0.5rem;
      color: $global-font-secondary-color;

      [data-theme='dark'] & {
        color: $global-font-secondary-color-dark;
      }
    }
  }
}

.home.posts {
  .home-profile {
    padding: 2rem 0;
  }

  .home-avatar img {
    width: 6rem;
  }

    .summary-card {
      margin: 0 auto;
      display: flex;
      list-style: none;
      align-items: flex-start;
      justify-content: space-around;
      flex-direction: row;
      flex-wrap: wrap;
      padding-top: 1rem;
    }

  .summary {
    background-color: $global-background-card-color;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      @include border-radius(.5rem);
      color: $global-font-color;
      width: 49%;
      @include transition(transform 0.4s ease);
      @include box-shadow(0 2px 4px -1px rgba(0, 0, 0, 0.2));
    
      &[data-image=hide] {
        min-height: 13rem;
      }
    
      &:hover {
        @include transform(scale(1.01));
      }
    
      [data-theme=dark] & {
        background-color: $global-background-card-color-dark;
        color: $global-font-color-dark;
      }
    
      .featured-image-preview {
        width: 100%;
        padding: 50% 0 0;
        position: relative;
        margin: 0 auto;
    
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          @include object-fit(cover);
          @include border-radius(.5rem .5rem 0 0);
        }
    
        img.lazyloaded {
          @include object-fit(cover);
        }
      }
    
      .single-title {
        font-size: 1.25rem;
        line-height: 140%;
        margin: 0.4rem 1rem;
        padding-top: 1rem;
      }
    
      .post-meta {
        font-size: .875rem;
        margin: 0 1rem .5rem;
        color: $global-font-secondary-color;
    
        span {
          display: inline-block;
        }
    
        [theme=dark] & {
          color: $global-font-secondary-color-dark;
        }
    
        @include link(false, true);
    
        .author {
          font-size: 1.05rem;
        }
      }
    
      p {
        margin: .5rem 0;
      }
    
      .content {
        @include box(vertical);
        -webkit-line-clamp: 2;
        margin: 0 1rem 0 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        @include overflow-wrap(break-word);
        color: $global-font-secondary-color;
    
        [theme=dark] & {
          color: $global-font-secondary-color-dark;
        }
    
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          font-size: 1rem;
          line-height: 1.5;
          display: inline;
    
          &::after {
            content: "\A";
            white-space: pre;
          }
        }
    
        h2 {
          font-size: 1.125rem;
        }
    
        @include link(false, true);
    
        b,
        strong {
          color: $global-font-secondary-color;
    
          [theme=dark] & {
            color: $global-font-secondary-color-dark;
          }
        }
      }
    
      .post-footer {
        margin: .5rem 1rem 0 1rem;
        font-size: .875rem;
    
        .post-tags {
          list-style: none;
          margin: 0;
          padding: 0;
          overflow: auto;
          white-space: nowrap;
    
          li {
            display: inline-block;
          }

          .post-tag:not(:last-child)::after {
            content: '';
            margin-right: 0.25rem;
          }
    
          a {
            color: $global-font-secondary-color;
            display: inline-block;
            line-height: 26px;
            padding: 0 10px;
            position: relative;
            margin: 0 5px 5px 0;
            text-decoration: none;
            -webkit-transition: color 0.2s;
            @include border-radius(.25rem);
            border: 1px solid;
    
            &:hover {
              background-color: $global-background-color;
              color: $global-link-hover-color;
            }
    
            [theme=dark] & {
              color: $global-font-secondary-color-dark;
    
              &:hover {
                background-color: $global-background-color-dark;
                color: $global-link-hover-color-dark;
              }
            }
          }
        }
      }
    }
}
